.kanban-board {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: flex; /* Keep flex display for wrapping */
    flex-wrap: wrap; /* Allow wrapping */
    /*border: 1px solid black;*/
    width: 100%; /* Ensure the kanban board takes full width */
}

.container-board {
    border: 1px dashed #D3D3D3;
    border-radius: 10px;
    display: flex;
    flex-direction: row; /* Keep row direction for cards */
    flex-wrap: wrap; /* Allow wrapping within the container */
    align-items: flex-start; /* Align items at the start */
    margin-top: 20px;
    padding: 10px; /* Optional: Add padding for better spacing */
    width: 100%; /* Ensure the container takes full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.card-item {
    flex: 1 1 calc(33.333% - 20px); /* Adjust card width to fit within the container and wrap */
    margin: 10px; /* Optional: Add margin for better spacing */
    min-width: 200px; /* Optional: Set a minimum width for the cards */
}

.project-card {
    max-width: 100%;
}

.delete-icon:hover {
    color: red;
}

.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}
